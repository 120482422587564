<template>
    <div>
        <!-- pdf -->
        <div class="pdf-container" v-bind:style="{'height': pdf_height +'px'}">
            <vue-pdf-app :pdf="pdf_manual_servicios"></vue-pdf-app>
        </div>

        <!-- Modal upload -->
        <v-dialog
            v-model="dialog"
            width="600px"
        >
            <v-card>
                <v-card-title class="green darken-1">
                    Subir manual de Servicios Wellboat
                </v-card-title>
                <v-card-text>
                    <v-row class="mx-2">
                        <v-file-input v-model="file" accept=".pdf" label="Archivo PDF"></v-file-input>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        @click="dialog = false"
                    >Cancelar</v-btn>
                    <v-btn
                        color="primary"
                        @click="uploadManual"
                    >Subir</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- / Modal -->
        
        <v-snackbar
            v-model="snackbar"
            :timeout="5000"
            :color="color"
        >
            {{ validation_message }}

            <template v-slot:action="{ attrs }">
                <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                >
                Cerrar
                </v-btn>
            </template>
        </v-snackbar>

        <v-btn
            class="mx-2"
            color="green darken-1"
            dark
            right
            fab
            small
            @click="dialog = !dialog"
             v-if="user.rol && user.rol.id == 1"
        >
            <i class="material-icons">upload</i>
        </v-btn>
    </div>
</template>
<style scoped>
    /deep/ .pdf-app .toolbar {
        z-index: 1 !important;
    }
</style>
<script>
import {mapActions, mapState, mapMutations} from 'vuex';
import VuePdfApp from "vue-pdf-app";
import "vue-pdf-app/dist/icons/main.css";

export default {
    data:()=>({
        panel: 0,
        pdf_manual_servicios: '',
        file: null,
        dialog: false,
        snackbar: false,
        pdf_height: 750,
        color: 'green',
        validation_message: ''
    }),
    mounted(){
        if(window.innerHeight < 768){
            this.pdf_height = 600;
        }
        this.manualServicios();
    },
    methods:{
        async manualServicios(){
            let url = this.base_url + "ayuda/servicios";
            await this.axios.get(url, {responseType: 'blob'}).then(response => {
                const blob = new Blob([response.data]);
                const objectUrl = URL.createObjectURL(blob);
                this.pdf_manual_servicios = objectUrl;
            });
        },
        async uploadManual(){
            this.headers.headers['Content-Type'] = "multipart/form-data";
            let url = `${this.base_url}uploads/ayuda/servicios`;
            let formData = new FormData();
            formData.append("pdf", this.file);
            await this.axios.post(url, formData, this.headers).then(() => {
                this.file = null;
                this.upload = false;
                location.reload();
            });
        },
        ...mapActions(['iniciarSession']),
        ...mapMutations(['mostrarLoading', 'ocultarLoading'])
    },
    computed:{
        ...mapState(['base_url', 'headers', 'storage_url', 'user'])
    },
    components:{
        VuePdfApp
    }
}
</script>